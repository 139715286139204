@font-face {
  font-weight: 400;
  font-family: Audi Type Extended Normal;
  font-style: normal;
  font-stretch: 130%;
  src: url("AudiTypeVF.2975704d.woff2") format("woff2-variations");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: Audi Type Extended Bold;
  font-style: normal;
  font-stretch: 130%;
  src: url("AudiTypeVF.2975704d.woff2") format("woff2-variations");
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: Audi Type Normal;
  font-style: normal;
  font-stretch: 100%;
  src: url("AudiTypeVF.2975704d.woff2") format("woff2-variations");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: Audi Type Bold;
  font-style: normal;
  font-stretch: 100%;
  src: url("AudiTypeVF.2975704d.woff2") format("woff2-variations");
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: Audi Type Wide Normal;
  font-style: normal;
  font-stretch: 105%;
  src: url("AudiTypeVF.2975704d.woff2") format("woff2-variations");
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: Audi Type Wide Bold;
  font-style: normal;
  font-stretch: 105%;
  src: url("AudiTypeVF.2975704d.woff2") format("woff2-variations");
  font-display: swap;
}

.audi-font-order-1, h1 {
  letter-spacing: -.015em;
  font-size: 28px;
  line-height: 44px;
}

.audi-font-order-2, h2 {
  letter-spacing: -.015em;
  font-size: 24px;
  line-height: 36px;
}

.audi-font-order-3, h3 {
  letter-spacing: -.015em;
  font-size: 20px;
  line-height: 32px;
}

.audi-font-order-4, h4 {
  letter-spacing: -.015em;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: 1024px) {
  .audi-font-order-1, h1 {
    font-size: 36px;
    line-height: 1.2;
  }

  .audi-font-order-2, h2 {
    font-size: 28px;
    line-height: 44px;
  }
}

@media screen and (min-width: 1440px) {
  .audi-font-order-1, h1 {
    font-size: 40px;
    line-height: 1.2;
  }

  .audi-font-order-2, h2 {
    font-size: 32px;
    line-height: 1.2;
  }

  .audi-font-order-3, h3 {
    font-size: 24px;
    line-height: 36px;
  }

  .audi-font-order-4, h4 {
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (min-width: 1920px) {
  .audi-font-order-1, h1 {
    font-size: 44px;
    line-height: 68px;
  }

  .audi-font-order-2, h2 {
    font-size: 36px;
    line-height: 42px;
  }

  .audi-font-order-3, h3 {
    font-size: 24px;
    line-height: 36px;
  }

  .audi-font-order-4, h4 {
    font-size: 20px;
    line-height: 32px;
  }
}

:root {
  --black: #000;
  --grey-80: #333;
  --grey-70: #4c4c4c;
  --grey-60: #666;
  --progressive-red: #f50537;
  --signal-red: #eb0d3f;
}

body {
  font-family: Audi Type Wide Normal, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

h1, h2, h3, h4 {
  color: var(--black);
  font-family: Audi Type Extended Normal, sans-serif;
}

input, input:focus, input:active, button, button:focus, button:active, input[type="text"], input[type="text"]:focus, input[type="text"]:active, input[type="url"], input[type="url"]:focus, input[type="url"]:active, input[type="time"], input[type="time"]:focus, input[type="time"]:active, textarea, textarea:focus, textarea:active, select, select:focus, select:active {
  box-shadow: none;
  border-color: var(--grey-60);
  outline: none;
}

input[type="text"], input[type="url"], input[type="time"] {
  margin-bottom: 1px;
  display: inline;
}

input[type="text"]:focus, input[type="url"]:focus, input[type="time"]:focus {
  border-bottom: 2px solid var(--black);
  margin-bottom: 0;
}

input[type="radio"], input[type="checkbox"] {
  color: #000;
  border-color: #000;
}

input[type="radio"], input[type="radio"]:focus, input[type="checkbox"], input[type="checkbox"]:focus {
  --tw-ring-color: transparent;
}

input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpolygon points='4.945 12 0 7.699 0.667 6.92 4.708 10.435 11.132 0 12 0.543 4.945 12' /%3E%3C/svg%3E");
  background-size: 50%;
  border-color: #000;
}

input[type="checkbox"]:checked, input[type="checkbox"]:checked:active, input[type="checkbox"]:checked:hover, input[type="checkbox"]:checked:focus {
  background-color: #0000;
  border-color: #000;
}

input[type="checkbox"] label {
  cursor: pointer;
}

.btn {
  min-width: 9em;
}

.btn--large {
  min-width: 14em;
}

.btn--primary {
  color: #fff;
  background-color: #000;
}

.btn--primary:active, .btn--primary:hover, .btn--primary:focus {
  background-color: #4c4c4c;
}

.btn--secondary {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.btn--secondary:active, .btn--secondary:hover, .btn--secondary:focus {
  border-color: #4c4c4c;
}

.language-switcher {
  text-align: right;
  position: absolute;
  top: -2rem;
  right: -1rem;
}

@media screen and (min-width: 640px) {
  .language-switcher {
    top: -3rem;
  }
}

.language-switcher > * {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #333;
  z-index: 10;
  text-align: left;
  font-family: Audi Type Wide Normal;
  font-size: 14px;
  line-height: 1.5;
  display: inline-block;
  position: relative;
}

.language-switcher #kc-current-locale-link {
  align-items: center;
  gap: .3rem;
  padding: .25em 0;
  display: flex;
}

.language-switcher #kc-current-locale-link:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 15.5C9.88279 15.5 11.4091 12.1421 11.4091 8C11.4091 3.85786 9.88279 0.5 8 0.5M8 15.5C6.11721 15.5 4.59091 12.1421 4.59091 8C4.59091 3.85786 6.11721 0.5 8 0.5M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5M8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5M0.926764 10.5H15.0732M0.926764 5.5H15.0732' stroke='black'/%3E%3C/svg%3E%0A");
  width: .9rem;
  height: .9rem;
  display: block;
}

.language-switcher .select-list {
  color: #fff;
  background: #333;
  min-width: 100%;
  max-height: 250px;
  font-size: 14px;
  display: none;
  position: absolute;
  overflow: scroll;
}

.language-switcher .select-list__item {
  cursor: pointer;
  padding: .5em;
  display: block;
}

.language-switcher .select-list__item:hover {
  background: #4d4d4d;
}

.language-switcher:hover .select-list {
  display: block;
}

/*# sourceMappingURL=base.css.map */
