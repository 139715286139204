
@font-face {
  font-weight: 400;
  font-family: 'Audi Type Extended Normal';
  font-style: normal;
  font-stretch: 130%;
  src: url('../fonts/AudiTypeVF.woff2') format('woff2-variations');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: 'Audi Type Extended Bold';
  font-style: normal;
  font-stretch: 130%;
  src: url('../fonts/AudiTypeVF.woff2') format('woff2-variations');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: 'Audi Type Normal';
  font-style: normal;
  font-stretch: 100%;
  src: url('../fonts/AudiTypeVF.woff2') format('woff2-variations');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: 'Audi Type Bold';
  font-style: normal;
  font-stretch: 100%;
  src: url('../fonts/AudiTypeVF.woff2') format('woff2-variations');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: 'Audi Type Wide Normal';
  font-style: normal;
  font-stretch: 105%;
  src: url('../fonts/AudiTypeVF.woff2') format('woff2-variations');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: 'Audi Type Wide Bold';
  font-style: normal;
  font-stretch: 105%;
  src: url('../fonts/AudiTypeVF.woff2') format('woff2-variations');
  font-display: swap;
}