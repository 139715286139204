@import "fonts";
@import "mixins";

:root {
  --black: #000000;
  --grey-80: #333333;
  --grey-70: #4c4c4c;
  --grey-60: #666;
  --progressive-red:#f50537;
  --signal-red: #eb0d3f;
}

@layer base {

  body {
    font-family: 'Audi Type Wide Normal', sans-serif;
    font-size: 16px;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: 'Audi Type Extended Normal', sans-serif;
    color: var(--black);
  }

  h1 {
    @extend .audi-font-order-1;
  }

  h2 {
    @extend .audi-font-order-2;
  }

  h3 {
    @extend .audi-font-order-3;
  }

  h4 {
    @extend .audi-font-order-4;
  }

  input, button,
  input[type=text],
  input[type=url],
  input[type=time],
  textarea,
  select {
    &,
    &:focus,
    &:active {
      box-shadow: none;
      border-color: var(--grey-60);
      outline: none;
    }
  }

  input[type=text],
  input[type=url],
  input[type=time] {
    margin-bottom: 1px;
    display: inline;

    &:focus {
      border-bottom: 2px solid var(--black);
      margin-bottom: 0px;
    }
  }

  input[type=radio],
  input[type=checkbox] {
    color: black;
    border-color:#000;

    &,
    &:focus {
      --tw-ring-color: transparent;
    }
  }

  input[type=checkbox]:checked {
    background-size: 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpolygon points='4.945 12 0 7.699 0.667 6.92 4.708 10.435 11.132 0 12 0.543 4.945 12' /%3E%3C/svg%3E");
    border-color:#000;

    &,
    &:active,
    &:hover,
    &:focus {
      background-color: transparent;
      border-color:#000;
    }
  }

  input[type=checkbox] label {
    cursor: pointer;
  }

  .btn {
    min-width: 9em;

    &--large {
      min-width: 14em;
    }

    &--primary {
      background-color: black;
      color: white;

      &:active,
      &:hover,
      &:focus {
        background-color: #4c4c4c;
      }
    }

    &--secondary {
      background-color: white;
      border: 1px solid black;
      color: black;

      &:active,
      &:hover,
      &:focus {
        border-color: #4c4c4c;
      }
    }
  }

  .language-switcher {
    text-align: right;
    position: absolute;
    right: -1rem;
    top: -2rem;

    @media screen and (min-width: 640px) {
      top: -3rem;
    }

    > * {
      position: relative;
      cursor: pointer;
      user-select: none;
      color: #333;
      font-family: "Audi Type Wide Normal";
      font-size: 14px;
      line-height: 1.5;
      z-index: 10;
      display: inline-block;
      text-align: left;
    }

    #kc-current-locale-link {
      display: flex;
      align-items: center;
      gap: .3rem;
      padding: 0.25em 0;

      &:before {
        content: "";
        display: block;
        width: .9rem;
        height: .9rem;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 15.5C9.88279 15.5 11.4091 12.1421 11.4091 8C11.4091 3.85786 9.88279 0.5 8 0.5M8 15.5C6.11721 15.5 4.59091 12.1421 4.59091 8C4.59091 3.85786 6.11721 0.5 8 0.5M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5M8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5M0.926764 10.5H15.0732M0.926764 5.5H15.0732' stroke='black'/%3E%3C/svg%3E%0A");
      }
    }

    .select-list {
      display: none;
      position: absolute;
      background: #333;
      color: white;
      font-size: 14px;
      min-width: 100%;
      max-height: 250px;
      overflow: scroll;

     &__item {
      padding: 0.5em;
      display: block;
      cursor: pointer;

      &:hover {
        background: #4d4d4d;
      }
     }
    }

    &:hover {
      .select-list {
        display: block;
      }
    }
  }
}